<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',  
}
</script>

<style>
body,html{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
html{font-size:26.67vw}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px; 
}
p{
  margin:0;
}
</style>
