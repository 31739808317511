import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const constantRoutes=[
  {
    path: '/',
    name: 'login',
    redirect:'/login'
},
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('@/view/login.vue'),
    },
    {
      path: '/meetList',
      name: 'meetList',
      meta: { title: '培训' },
      component: () => import('@/view/meetList/index.vue'),
    },
    {
      path: '/meetDetail',
      name: 'meetDetail',
      meta: { title: '培训详情' },
      component: () => import('@/view/meet/detail.vue'),
    },
    {
      path: '/meetIntroduce',
      name: 'meetIntroduce',
      meta: { title: '培训介绍' },
      component: () => import('@/view/meet/introduce.vue'),
    },
    // {
    //   path: '/previewFile',
    //   name: 'previewFile',
    //   meta: { title: '培训通告' },
    //   component: () => import('@/view/meet/previewFile.vue'),
    // },
    {
      path: '/meetSchedule',
      name: 'meetSchedule',
      meta: { title: '培训日程' },
      component: () => import('@/view/meet/schedule.vue'),
    },
    {
      path: '/meetTraffic',
      name: 'meetTraffic',
      meta: { title: '交通指引' },
      component: () => import('@/view/meet/traffic.vue'),
    },
    {
      path: '/meetSignUp',
      name: 'meetSignUp',
      meta: { title: '报名缴费' },
      component: () => import('@/view/meet/signUp.vue'),
    },
    {
      path: '/editAttendee',
      name: 'editAttendee',
      meta: { title: '参会人信息' },
      component: () => import('@/view/meet/editAttendee.vue'),
    },
    {
      path: '/signSuccess',
      name: 'signSuccess',
      meta: { title: '报名成功' },
      component: () => import('@/view/result/signSuccess.vue'),
    },
    {
      path: '/signFailed',
      name: 'signFailed',
      meta: { title: '报名失败' },
      component: () => import('@/view/result/signFailed.vue'),
    },
    {
      path: '/invoiceSuccess',
      name: 'invoiceSuccess',
      meta: { title: '开票提交成功' },
      component: () => import('@/view/result/invoiceSuccess.vue'),
    },
    {
      path: '/invoiceFailed',
      name: 'invoiceFailed',
      meta: { title: '开票提交失败' },
      component: () => import('@/view/result/invoiceFailed.vue'),
    },
    {
      path: '/invoice',
      name: 'invoice',
      meta: { title: '开具发票' },
      component: () => import('@/view/invoice/index.vue'),
    },
    {
      path: '/my',
      name: 'my',
      meta: { title: '我的' },
      component: () => import('@/view/my/index.vue'),
    },
    {
      path: '/myData',
      name: 'myData',
      meta: { title: '我的资料' },
      component: () => import('@/view/my/myData.vue'),
    },
    {
      path: '/myMeetList',
      name: 'myMeetList',
      meta: { title: '我的培训' },
      component: () => import('@/view/myMeet/meetList.vue'),
    },
    {
      path: '/myMeetDetail',
      name: 'myMeetDetail',
      meta: { title: '培训详情' },
      component: () => import('@/view/myMeet/meetDetail.vue'),
    },
    {
      path: '/myMemberList',
      name: 'myMemberList',
      meta: { title: '参会人员' },
      component: () => import('@/view/myMeet/myMemberList.vue'),
    },
    {
      path: '/applyRefund',
      name: 'applyRefund',
      meta: { title: '退费申请' },
      component: () => import('@/view/myMeet/applyRefund.vue'),
    },
    {
      path: '/refundRecords',
      name: 'refundRecords',
      meta: { title: '退费记录' },
      component: () => import('@/view/myMeet/refundRecords.vue'),
    },
    {
      path: '/myInvoice',
      name: 'myInvoice',
      meta: { title: '我的发票' },
      component: () => import('@/view/myInvoice/index.vue'),
    },
    {
      path: '/previewInvoice',
      name: 'previewInvoice',
      meta: { title: '我的发票' },
      component: () => import('@/view/myInvoice/previewInvoice.vue'),
    },
    {
      path: '/memberPay',
      name: 'memberPay',
      meta: { title: '会员缴费' },
      component: () => import('@/view/memberPay/index.vue'),
    },
    {
      path: '/memberType',
      name: 'memberType',
      meta: { title: '会员类型' },
      component: () => import('@/view/memberPay/memberType.vue'),
    },
    {
      path: '/memberRecords',
      name: 'memberRecords',
      meta: { title: '缴费记录' },
      component: () => import('@/view/memberPay/records.vue'),
    },
    {
      path: '/memberRecord',
      name: 'memberRecord',
      meta: { title: '缴费详情' },
      component: () => import('@/view/memberPay/payDetail.vue'),
    },
    {
      path: '/memberPayFee',
      name: 'memberPayFee',
      meta: { title: '会员缴费' },
      component: () => import('@/view/memberPay/pay.vue'),
    },
    {
      path: '/paySuccess',
      name: 'paySuccess',
      meta: { title: '缴费结果' },
      component: () => import('@/view/result/paySuccess.vue'),
    },
    {
      path: '/payFailed',
      name: 'payFailed',
      meta: { title: '缴费失败' },
      component: () => import('@/view/result/payFailed.vue'),
    },
    {
      path: '/payFailed',
      name: 'payFailed',
      meta: { title: '缴费失败' },
      component: () => import('@/view/result/payFailed.vue'),
    },



];
const createRouter = () => new Router({
     mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

  const router = createRouter()

  // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
  export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
  }

  const hasClientId=()=>{
    //以后注释掉这一句
    //localStorage.setItem('clientId',"1FEKN7EH1EFEFB26AK8MFPGNAC0017SV")

    let clientId=localStorage.getItem('clientId');
    let bool=clientId?true:false;
    return bool
  }
  router.beforeEach((to, from, next) => {
    console.log(!hasClientId(),'hasClientId')
    if (to.name == 'my' && !hasClientId()) next()
    else if (to.name !== 'login' && !hasClientId()) next({ name: 'login' })
    else next()
  })

  export default router
