import Vue from 'vue'
import App from './App.vue'
import VueWechatTitle from 'vue-wechat-title'
import router from "./router"
import { PullRefresh,Toast} from 'vant';


Vue.config.productionTip = false
Vue.use(VueWechatTitle);
Vue.use(PullRefresh);
Vue.use(Toast)



new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
